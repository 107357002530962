<template>
    <div>
        <other-services-component/>
        <footer-component/>
    </div>
</template>

<script>
    import OtherServicesComponent from "@/components/OtherServicesComponent";
    import FooterComponent from "@/layouts/app-layout/footer/FooterComponent";
    
    export default {
        name: "OtherServices",
        title: "Otros Servicios | Turismo BC",
        components: {
            OtherServicesComponent,
            FooterComponent
        }
    }
</script>

<style scoped>

</style>